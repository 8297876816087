















































.main-no-found {
  min-height: 570px;
}
.no-found {
  display: block;
  width: 332px;
  margin: 136px auto 0;
}
.backIndex {
  display: block;
  width: 187px;
  height: 40px;
  background: #00afe8;
  border-radius: 4px;
  margin: 44px auto 0;
  font-size: 14px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  &:hover {
    opacity: 0.9;
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";