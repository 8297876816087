.main-no-found {
  min-height: 570px;
}
.no-found {
  display: block;
  width: 332px;
  margin: 136px auto 0;
}
.backIndex {
  display: block;
  width: 187px;
  height: 40px;
  background: #00afe8;
  border-radius: 4px;
  margin: 44px auto 0;
  font-size: 14px;
  color: #fff;
  text-align: center;
  line-height: 40px;
}
.backIndex:hover {
  opacity: 0.9;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
