.main-no-found[data-v-2be985f9] {
  min-height: 570px;
}
.no-found[data-v-2be985f9] {
  display: block;
  width: 332px;
  margin: 136px auto 0;
}
.backIndex[data-v-2be985f9] {
  display: block;
  width: 187px;
  height: 40px;
  background: #00afe8;
  border-radius: 4px;
  margin: 44px auto 0;
  font-size: 14px;
  color: #fff;
  text-align: center;
  line-height: 40px;
}
.backIndex[data-v-2be985f9]:hover {
  opacity: 0.9;
}
.ellipsis[data-v-2be985f9] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-2be985f9] {
  *zoom: 1;
}
.clearfix[data-v-2be985f9]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-2be985f9] {
  float: left;
}
.fr[data-v-2be985f9] {
  float: right;
}


/*# sourceMappingURL=chunk-e0389fa8.f71682ce.css.map*/